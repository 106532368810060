<template>
  <header
    class="sticky top-0 z-50 w-full backdrop-blur flex-none border-b border-gray-200 dark:border-gray-700 bg-white/75 dark:bg-gray-900/75"
  >
    <UContainer>
      <div class="h-14 flex items-center justify-between gap-3">
        <div class="flex gap-5">
          <div class="lg:flex-1 flex items-center gap-1.5">
            <Logo />
          </div>
          <div class="hidden lg:flex gap-2">
            <UButton
              v-if="userStore.isAdmin"
              color="fuchsia"
              variant="soft"
              icon="i-simple-icons-phpmyadmin"
              label="Admin"
              target="_blank"
              to="https://kungfutech-admin.pages.dev/"
            />
            <Courses />
            <nuxt-link
              :prefetch="false"
              :to="
                userStore.isFptMarket
                  ? '/phong-van-pro/fpt/prf192'
                  : userStore.isGlobalMarket
                  ? '/phong-van-pro/full-stack-web-and-mobile-global/java-global'
                  : '/phong-van-pro/full-stack-web-and-mobile/java'
              "
            >
              <UButton variant="soft" class="relative"
                >{{ userStore.isFptMarket ? "Question bank" : LL.interview() }}
                <UBadge
                  color="fuchsia"
                  variant="solid"
                  class="px-[5px] py-[1px] absolute top-[-7px] right-[-20px] z-10"
                >
                  PRO
                </UBadge>
              </UButton>
            </nuxt-link>
            <UButton variant="soft" to="/posts?tab=new"> Posts</UButton>
            <UButton variant="soft" to="/english">5K</UButton>
            <ClientOnly>
              <UPopover>
                <UButton
                  variant="soft"
                  trailing-icon="i-heroicons-ellipsis-horizontal-20-solid"
                  aria-labelledby="kungfutech network"
                />

                <template #panel>
                  <div class="p-2">
                    <div
                      class="px-2 py-1 border-dashed border-[1px] rounded-md flex border-gray-200 dark:border-gray-600"
                    >
                      <ImagePreset
                        src="/images/navbar/mely-demy.png"
                        width="70"
                        height="24"
                        alt="Mely demy"
                      />
                      <div class="ml-1">
                        <nuxt-link
                          to="https://academy.techmely.com/"
                          target="_blank"
                          class="font-bold text-xl"
                          >Mely Demy</nuxt-link
                        >
                        <p class="text-sm mb-1">Khoá học 29K</p>
                        <UButton
                          to="https://academy.techmely.com/"
                          variant="soft"
                          color="gray"
                          label="Xem thêm"
                          trailing-icon="i-heroicons-cursor-arrow-ripple"
                          target="_blank"
                        />
                      </div>
                    </div>
                    <div
                      class="px-2 py-1 border-dashed border-[1px] rounded-md mt-2 flex border-gray-200 dark:border-gray-600"
                    >
                      <ImagePreset
                        src="/images/navbar/tools.png"
                        width="70"
                        height="24"
                        alt="Tools"
                      />
                      <div class="ml-1">
                        <nuxt-link
                          to="https://tools.techmely.com/"
                          target="_blank"
                          class="font-bold text-xl"
                          >Mely Tools</nuxt-link
                        >
                        <p class="text-sm mb-1">Tools cho developer</p>
                        <UButton
                          to="https://tools.techmely.com/"
                          variant="soft"
                          color="gray"
                          label="Xem thêm"
                          target="_blank"
                          trailing-icon="i-heroicons-cursor-arrow-ripple"
                        />
                      </div>
                    </div>
                    <div
                      class="px-2 py-1 border-dashed border-[1px] rounded-md mt-2 flex border-gray-200 dark:border-gray-600"
                    >
                      <ImagePreset
                        src="/images/navbar/shopping.png"
                        width="70"
                        height="24"
                        alt="Mely demy"
                      />
                      <div class="ml-1">
                        <nuxt-link
                          to="https://printub.com/coll/phong-cach-lap-trinh"
                          target="_blank"
                          class="font-bold text-xl"
                          >Mely Store</nuxt-link
                        >
                        <p class="text-sm mb-1">Phong cách lập trình</p>
                        <UButton
                          to="https://printub.com/coll/phong-cach-lap-trinh"
                          variant="soft"
                          color="gray"
                          label="Xem thêm"
                          target="_blank"
                          trailing-icon="i-heroicons-cursor-arrow-ripple"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </UPopover>
            </ClientOnly>
          </div>
        </div>
        <div class="flex gap-1 md:gap-3">
          <LazyClientOnly>
            <LazyNavbarSearch />
          </LazyClientOnly>
          <div v-if="userStore.isLogged" class="flex gap-3">
            <User />
            <UButton
              v-if="userStore.isShowUpgradeAccount"
              class="hidden lg:flex"
              variant="soft"
              color="fuchsia"
              icon="i-heroicons-rocket-launch"
              to="/nang-cap-tai-khoan"
            >
              {{ LL.upgrade_account() }}
            </UButton>
          </div>
          <div v-else class="flex gap-3">
            <ButtonOpenAuthModal />
            <UButton color="teal" class="hidden lg:block" variant="outline" to="/dang-ky">
              {{ LL.register() }}
            </UButton>
          </div>
          <div class="flex items-center">
            <PageNavbarToggle />
            <Settings />
          </div>
        </div>
      </div>
    </UContainer>
  </header>
</template>

<script lang="ts" setup>
import User from "./User.vue";
import Courses from "./Courses.vue";
import Settings from "./Settings.vue";
import { useUserStore } from "store/user";
import { typesafeI18n } from "~/locales/i18n-vue";
import ButtonOpenAuthModal from "~/components/AuthModal/ButtonOpenAuthModal.vue";

const { LL } = typesafeI18n();
const userStore = useUserStore();

export interface IMenuItem {
  type: "link" | "button";
  text: string;
  href?: string;
  route?: string;
}
</script>
