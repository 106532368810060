<template>
  <div class="block lg:hidden">
    <LazyClientOnly>
      <UButton
        icon="i-heroicons-bars-3"
        color="white"
        variant="soft"
        aria-label="Theme"
        @click="handleOpenMenu"
      />
      <template #fallback>
        <div class="w-8 h-8">
          <USkeleton class="w-7 h-7" />
        </div>
      </template>
    </LazyClientOnly>
    <LazyUSlideover v-model="isOpen">
      <UCard
        class="flex flex-col flex-1"
        :ui="{
          header: { padding: 'px-4 py-[14px]' },
        }"
      >
        <template #header>
          <div class="flex items-center justify-between">
            <Logo />
            <UButton
              color="gray"
              variant="ghost"
              icon="i-heroicons-x-mark-20-solid"
              class="-my-1"
              @click="isOpen = false"
            />
          </div>
        </template>
        <LazyUCommandPalette
          ref="commandPaletteRef"
          :empty-state="{
            label: LL.no_results(),
            queryLabel: LL.no_results(),
          }"
          :placeholder="`${LL.search()}...`"
          :groups="groups"
          :fuse="{ resultLimit: 6, fuseOptions: { threshold: 0.1 } }"
          @update:model-value="onSelect"
        />
      </UCard>
    </LazyUSlideover>
  </div>
</template>

<script lang="ts" setup>
import { typesafeI18n } from "~/locales/i18n-vue";

const isOpen = ref(false);
const { LL } = typesafeI18n();
const { groups, onSelect } = useSearch();
const route = useRoute();

const handleOpenMenu = () => {
  isOpen.value = !isOpen.value;
};

watch(
  () => route.query,
  (_) => {
    isOpen.value = false;
  },
  { immediate: true, deep: true }
);
</script>
