<template>
  <LazyUDropdown :items="items" :popper="{ placement: 'bottom-start' }">
    <LazyUAvatar
      :src="userStore.image_url"
      alt="Avatar"
      :chip-color="getColorChip"
      :chip-text="userStore.role"
      chip-position="bottom-left"
      loading="lazy"
    />
  </LazyUDropdown>
</template>

<script lang="ts" setup>
import { typesafeI18n } from "~/locales/i18n-vue";
import { useUserStore } from "~/store";

const userStore = useUserStore();
const { LL } = typesafeI18n();

const getColorChip = computed(() => {
  switch (userStore.role) {
    case "Diamond":
      return "cyan";
    case "Gold":
      return "amber";
    default:
      return "gray";
  }
});

const items = computed(() => [
  [
    {
      label: LL.value.my_profile(),
      icon: "i-heroicons-user-circle-20-solid",
      click: () => {
        navigateTo(`/users/${userStore.username}`);
      },
    },
  ],
  [
    {
      label: LL.value.update_profile(),
      icon: "i-heroicons-pencil-square-20-solid",
      click: () => {
        navigateTo("/users/me");
      },
    },
    {
      label: LL.value.change_password(),
      icon: "i-heroicons-wrench-20-solid",
      click: () => {
        navigateTo("/users/me/doi-mat-khau");
      },
    },
    {
      label: LL.value.upgrade_account(),
      icon: "i-heroicons-rocket-launch-20-solid",
      click: () => {
        navigateTo("/nang-cap-tai-khoan");
      },
    },
  ],
  [
    {
      label: LL.value.logout(),
      icon: "i-heroicons-arrow-left-on-rectangle-20-solid",
      click: () => {
        userStore.signOut();
      },
    },
  ],
]);
</script>
