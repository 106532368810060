import { default as _91slug_93yWNmz0xIRTMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/bai-viet/[category]/[slug].vue?macro=true";
import { default as _91slug_93lkcAxGPhTKMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/cau-hoi-phong-van/[slug].vue?macro=true";
import { default as dang_45kyH3Rqxgg04hMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/dang-ky.vue?macro=true";
import { default as _91slug_93NSMXmrdyjmMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/category/[slug].vue?macro=true";
import { default as indexgwdHp2TLpEMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/create/index.vue?macro=true";
import { default as _91id_93AAXjmyM5GTMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/detail/[id].vue?macro=true";
import { default as _91id_93M4kfi67yubMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/edit/[id].vue?macro=true";
import { default as _91id_93Ythuw4vs1RMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/flashcards/[id].vue?macro=true";
import { default as indexe7pFedLR5wMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/index.vue?macro=true";
import { default as _91id_93oddLn1h5RBMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/test/[id].vue?macro=true";
import { default as fpt3et7uldEVHMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/fpt.vue?macro=true";
import { default as indexA4o1l4xKaXMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/index.vue?macro=true";
import { default as jsjsIbMLh4zfMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/js.vue?macro=true";
import { default as _91slug_93LRVrIQ5BtbMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/khoa-hoc/[slug].vue?macro=true";
import { default as createMkadepkcLkMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/khoa-hoc/create.vue?macro=true";
import { default as _91slug_93BCRwJscAXZMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/khoa-hoc/edit/[slug].vue?macro=true";
import { default as indexg8NZB5c09xMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/khoa-hoc/index.vue?macro=true";
import { default as loadinggLOVMluwA8Meta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/loading.vue?macro=true";
import { default as nang_45cap_45tai_45khoanjsNtV1HEuQMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/nang-cap-tai-khoan.vue?macro=true";
import { default as cancelwQaSjGqSMgMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/payment/cancel.vue?macro=true";
import { default as successS8tzzBIsX8Meta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/payment/success.vue?macro=true";
import { default as _91category_93lNriykGAu5Meta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/phong-van-pro/[topic]/[category].vue?macro=true";
import { default as indexhmGRiH5otdMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/phong-van-pro/index.vue?macro=true";
import { default as _91slug_93exRIIq3N8oMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/posts/[slug].vue?macro=true";
import { default as createXBzrzFsy5LMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/posts/create.vue?macro=true";
import { default as _91slug_93DpQueqUhs9Meta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/posts/edit/[slug].vue?macro=true";
import { default as indexkfJLWU2RxEMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/posts/index.vue?macro=true";
import { default as _91slug_93y51IyNCtteMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/posts/topics/[slug].vue?macro=true";
import { default as privacy_45policyNL7wPvDUPiMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/privacy-policy.vue?macro=true";
import { default as tai_45khoaneZYRq1VKvCMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/tai-khoan.vue?macro=true";
import { default as _91username_93MDuVZmsaKiMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/users/[username].vue?macro=true";
import { default as doi_45mat_45khaufUtoqV8PoVMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/users/me/doi-mat-khau.vue?macro=true";
import { default as feedbackKf3Gb6KmaoMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/users/me/feedback.vue?macro=true";
import { default as index7eo0RzhHZAMeta } from "/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/users/me/index.vue?macro=true";
export default [
  {
    name: "bai-viet-category-slug",
    path: "/bai-viet/:category()/:slug()",
    meta: _91slug_93yWNmz0xIRTMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/bai-viet/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cau-hoi-phong-van-slug",
    path: "/cau-hoi-phong-van/:slug()",
    meta: _91slug_93lkcAxGPhTKMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/cau-hoi-phong-van/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dang-ky",
    path: "/dang-ky",
    meta: dang_45kyH3Rqxgg04hMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/dang-ky.vue").then(m => m.default || m)
  },
  {
    name: "english-category-slug",
    path: "/english/category/:slug()",
    meta: _91slug_93NSMXmrdyjmMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "english-create",
    path: "/english/create",
    meta: indexgwdHp2TLpEMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/create/index.vue").then(m => m.default || m)
  },
  {
    name: "english-detail-id",
    path: "/english/detail/:id()",
    meta: _91id_93AAXjmyM5GTMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: "english-edit-id",
    path: "/english/edit/:id()",
    meta: _91id_93M4kfi67yubMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "english-flashcards-id",
    path: "/english/flashcards/:id()",
    meta: _91id_93Ythuw4vs1RMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/flashcards/[id].vue").then(m => m.default || m)
  },
  {
    name: "english",
    path: "/english",
    meta: indexe7pFedLR5wMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/index.vue").then(m => m.default || m)
  },
  {
    name: "english-test-id",
    path: "/english/test/:id()",
    meta: _91id_93oddLn1h5RBMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/english/test/[id].vue").then(m => m.default || m)
  },
  {
    name: "fpt",
    path: "/fpt",
    meta: fpt3et7uldEVHMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/fpt.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexA4o1l4xKaXMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "js",
    path: "/js",
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/js.vue").then(m => m.default || m)
  },
  {
    name: "khoa-hoc-slug",
    path: "/khoa-hoc/:slug()",
    meta: _91slug_93LRVrIQ5BtbMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/khoa-hoc/[slug].vue").then(m => m.default || m)
  },
  {
    name: "khoa-hoc-create",
    path: "/khoa-hoc/create",
    meta: createMkadepkcLkMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/khoa-hoc/create.vue").then(m => m.default || m)
  },
  {
    name: "khoa-hoc-edit-slug",
    path: "/khoa-hoc/edit/:slug()",
    meta: _91slug_93BCRwJscAXZMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/khoa-hoc/edit/[slug].vue").then(m => m.default || m)
  },
  {
    name: "khoa-hoc",
    path: "/khoa-hoc",
    meta: indexg8NZB5c09xMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/khoa-hoc/index.vue").then(m => m.default || m)
  },
  {
    name: "loading",
    path: "/loading",
    meta: loadinggLOVMluwA8Meta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/loading.vue").then(m => m.default || m)
  },
  {
    name: "nang-cap-tai-khoan",
    path: "/nang-cap-tai-khoan",
    meta: nang_45cap_45tai_45khoanjsNtV1HEuQMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/nang-cap-tai-khoan.vue").then(m => m.default || m)
  },
  {
    name: "payment-cancel",
    path: "/payment/cancel",
    meta: cancelwQaSjGqSMgMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/payment/cancel.vue").then(m => m.default || m)
  },
  {
    name: "payment-success",
    path: "/payment/success",
    meta: successS8tzzBIsX8Meta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/payment/success.vue").then(m => m.default || m)
  },
  {
    name: "phong-van-pro-topic-category",
    path: "/phong-van-pro/:topic()/:category()",
    meta: _91category_93lNriykGAu5Meta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/phong-van-pro/[topic]/[category].vue").then(m => m.default || m)
  },
  {
    name: "phong-van-pro",
    path: "/phong-van-pro",
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/phong-van-pro/index.vue").then(m => m.default || m)
  },
  {
    name: "posts-slug",
    path: "/posts/:slug()",
    meta: _91slug_93exRIIq3N8oMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/posts/[slug].vue").then(m => m.default || m)
  },
  {
    name: "posts-create",
    path: "/posts/create",
    meta: createXBzrzFsy5LMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/posts/create.vue").then(m => m.default || m)
  },
  {
    name: "posts-edit-slug",
    path: "/posts/edit/:slug()",
    meta: _91slug_93DpQueqUhs9Meta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/posts/edit/[slug].vue").then(m => m.default || m)
  },
  {
    name: "posts",
    path: "/posts",
    meta: indexkfJLWU2RxEMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "posts-topics-slug",
    path: "/posts/topics/:slug()",
    meta: _91slug_93y51IyNCtteMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/posts/topics/[slug].vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyNL7wPvDUPiMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "tai-khoan",
    path: "/tai-khoan",
    meta: tai_45khoaneZYRq1VKvCMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/tai-khoan.vue").then(m => m.default || m)
  },
  {
    name: "users-username",
    path: "/users/:username()",
    meta: _91username_93MDuVZmsaKiMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/users/[username].vue").then(m => m.default || m)
  },
  {
    name: "users-me-doi-mat-khau",
    path: "/users/me/doi-mat-khau",
    meta: doi_45mat_45khaufUtoqV8PoVMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/users/me/doi-mat-khau.vue").then(m => m.default || m)
  },
  {
    name: "users-me-feedback",
    path: "/users/me/feedback",
    meta: feedbackKf3Gb6KmaoMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/users/me/feedback.vue").then(m => m.default || m)
  },
  {
    name: "users-me",
    path: "/users/me",
    meta: index7eo0RzhHZAMeta || {},
    component: () => import("/home/runner/work/kungfutech-fe-v2/kungfutech-fe-v2/pages/users/me/index.vue").then(m => m.default || m)
  }
]